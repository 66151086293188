
    import Vue from "vue";
    import Component from "vue-class-component";
    import { required, email } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import { BTab, BvEvent } from "bootstrap-vue";
    import * as toastr from "toastr";

    import ApiButton from "@/components/ApiButton.vue";
    import Contacts from "@/components/AdminSupplierTabs/Contacts.vue";
    import Users from "@/components/AdminSupplierTabs/Users.vue";
    import Communications from "@/components/AdminSupplierTabs/Communications.vue";
    import Payments from "@/components/AdminSupplierTabs/Payments.vue";
    import Histories from "@/components/AdminSupplierTabs/Histories.vue";
    import BuyersAndRegions from "@/components/AdminSupplierTabs/BuyersAndRegions.vue";
    import SupplierModulesTab from "@/components/SupplierModulesTab.vue";
    import SupplierDocuments from "@/components/AdminSupplierTabs/SupplierDocuments.vue";
    import Certificates from "@/components/AdminSupplierTabs/Certificates.vue";
    import MultiSelectList from "@/components/MultiSelectList.vue";
    import ModuleContent from "@/components/ModuleContent.vue";

    import store from "@/store/store";
    import router, { beforeRouteLeave } from "@/router/router";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import eventBus from "@/stuff/EventBus";
    import dirtyness from "@/stuff/Dirtyness";
    
    import { ILookupItem } from "@/model/LookupItem";
    import { User } from "@/model/User";
    import { SupplierTabCounts } from "@/model/SupplierTabCounts";
    import { Supplier as ModelSupplier } from "@/model/Supplier";
    import { IBuyerSupplier, BuyerSupplier } from "@/model/BuyerSupplier";
import { BuyerSupplierSearchParameters } from "@/model/BuyerSupplierSearchParameters";
import { TriState, UserRole } from "@/model/Enums";

    @Component({
        components: { 
            ApiButton, 
            Contacts, 
            Users,
            Payments, 
            BuyersAndRegions, 
            Histories, 
            Communications, 
            SupplierModulesTab, 
            Certificates,
            SupplierDocuments,
            MultiSelectList,
            ModuleContent
        },
        beforeRouteLeave
    })
    export default class Supplier extends Vue {

        async mounted() {
            const isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            this.buyerID = isBuyerZone
                ? store.state.signedInUser == null ? 0 : +store.state.signedInUser.buyerID
                : 0;
            this.supplierID = +this.$router.currentRoute.params.supplierID;
            await Promise.all([
                store.dispatch("loadCompanyTypeList"),
                store.dispatch("loadCountryList"),
                store.dispatch("loadTradeList"),
                store.dispatch("loadTradingAreaList"),
                this.loadBuyerList(),
                this.load(this.supplierID),
                this.loadBuyerSuppliers(),
                this.loadTabCounts()
            ]);

            // set own reactive property whenever global dirty flag changes
            eventBus.$on("dirtyness-changed", (isDirty: boolean) => {
                this.isDirty = isDirty;
            });
        }

        private checkUrlQueryTab() {
            const query = Object.assign({}, this.$route.query);
            const goToTab = query.goToTab;
            if(goToTab != null) {
                delete query.goToTab;
                this.$router.replace({ query });

                // TODO - add more tabs if needed and maybe replace with better mechanism
                if (goToTab === "modules") {
                    this.tabIndex = 7;
                }
                else if (goToTab === "payment") {
                    this.tabIndex = 3;
                }
                else if (goToTab === "history") {
                    this.tabIndex = 4;
                }
                else if (goToTab === "comms") {
                    this.tabIndex = 6;
                }
            }
        }

        //
        // -- properties
        //

        tabIndex: number = 0;
        supplierID: number = 0;
        buyerID: number = 0;
        isDirty: boolean = false; // we need a reactive property AND a global flag - so we have TWO flags (for now)
        readonly supplier = new ModelSupplier();
        readonly buyerSupplierList: Array<BuyerSupplier> = [];
        readonly tabCounts = new SupplierTabCounts();
        readonly dirtynessOptions = {
            model: this.supplier,
            isDeep: false,
            excludeList: dirtyness.defaultAuditFieldList()
        };

        private buyerList: Array<ILookupItem> = [];

        // computed
        get title(): string {
            if (!this.supplier) return "- - -";
            if (utils.isEmptyId(this.supplier.id)) return "New Supplier";
            return this.supplier.name ? this.supplier.name : "(No supplier name)";
        }

        get companyTypeList(): Array<ILookupItem> { return this.$store.state.companyTypeList; }
        get companyTypeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.companyTypeList); }

        get countryList(): Array<ILookupItem> { return this.$store.state.countryList; }
        get countryOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.countryList); }

        get tradingAreaList(): Array<ILookupItem> { return this.$store.state.tradingAreaList; }
        get tradingAreaOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.tradingAreaList); }

        get scopeList(): Array<ILookupItem> { return this.$store.state.scopeList; }
        get scopeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.scopeList); }

        get canSeeSaveSupplier() {
            // Details or HMRC tabs - this seems such a shit way to do this - based on index
            return (this.tabIndex === 0 || this.tabIndex === 10);
        }

        get canSeeDeleteSupplier() {
            return this.tabIndex === 0 && !this.supplier.isNew && !utils.hasDateValue(this.supplier.deleted);
        }

        get saveSupplierButtonText() {
            return utils.hasDateValue(this.supplier.deleted) ? "Save Supplier and Un-archive" : "Save Supplier";
        }

        get hasDormantDate() {
            return !this.supplier.isActive && utils.hasDateValue(this.supplier.dormantDate)
        }

        get canUpdateStatus(): boolean {
            if (this.supplier.isNew) return true;
            if (!this.$store.state.signedInUser) return false;
            return this.$store.state.signedInUser.role == UserRole.Admin
        }

        //
        // -- watchers
        //

        @Watch("supplier", { deep: true })
        onSupplierChanged() {
            dirtyness.dataChanged(this.dirtynessOptions);
        }

        //
        // -- methods
        //

        onTabsChanged(currentTabs: Array<BTab>, previousTabs: Array<BTab>) {
            console.log(`~~~~ onTabsChanged prev:${previousTabs.length} curr:${currentTabs.length}`);
        }

        async onTabActivated(newTabIndex: number, prevTabIndex: number, bvEvt: BvEvent) {

            console.log("onTabActivated - bvEvt", bvEvt);
            console.log("onTabActivated - newTabIndex", newTabIndex);

            // See if any unsaved changes first.  Needs to be synchronous by the look of it 
            // - hence window.confirm and NOT bvModal.msgBoxConfirm which is asynchronous.
            if(dirtyness.isDirty && confirm("If you leave this tab, your changes will not be saved.\n\nClick OK to stay on this tab or cancel to leave.")) {
                // stay on tab
                bvEvt.preventDefault();
                return;
            }

            // Bad juju - don't know if is better way to identify tab other than index (if there is, I can't find it).

            // if(newTabIndex === 0) { 
            //     // details tab
            //     this.load(this.supplierID);
            // }
            // if((this.supplier.isNew && newTabIndex === 1) || (!this.supplier.isNew && newTabIndex === 9)) { 
            //     // HMRC + banking tab
            //     this.load(this.supplierID);
            // }
        }

        goToSuppliers() {
            // show supplier search (we could go back - but no guarantee that we came from search)
            // TODO - maybe check history and go back?
            router.push("/suppliers");
        }

        // There's code in router.ts and store.ts to handle this
        // (this works across tabs becuase login is preserved in browser session storage
        // but storage is COPIED for new tab and only if it's a CHILD tab!)
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
        goToSupplierView() {
            const routeData = this.$router.resolve({ name: "SupplierZoneHome" });
            // update session storage - child tab will inherit this data!
            const user = this.$store.state.signedInUser as User;
            user.supplierID = this.supplierID;
            store.dispatch("setSignedInUser", user);
            // so new tab can get supplier name, etc. without going off to the server
            window.sessionStorage.setItem("supplierData", JSON.stringify(this.supplier));           
            window.open(routeData.href, "_blank");
        }

        async loadTabCounts() {
            const data = await apiClient.get(`/api/supplier/tabCounts?supplierID=${this.supplierID}&buyerID=${this.buyerID}`);
            this.tabCounts.update(data);
        }

        async loadBuyerList() {
            this.buyerList = await apiClient.get("/api/buyer/lookups?supplierID=0");
        }

        async load(supplierID: number) {
            if(supplierID > 0) {
                const serverSupplierData = await apiClient.get(`api/supplier/Load?id=${supplierID}&buyerID=${this.buyerID}`);

                this.$v.$reset();
                utils.resetObject(this.supplier);

                // nb - update maps suppliers and contacts too
                this.supplier.update(serverSupplierData);
                dirtyness.setClean(this.dirtynessOptions);

                // next tick fires after DOM updated - but I don't think it helps much here
                this.$nextTick(() => {
                    // Such a hack. Maybe we could/should do something in conjunction with onTabsChanged...
                    setTimeout(() => { 
                        this.checkUrlQueryTab(); 
                    }, 100); 
                });
            }
            else {
                this.$v.$reset();
                utils.resetObject(this.supplier);
                dirtyness.setClean(this.dirtynessOptions);
            }
        }

        async loadBuyerSuppliers() {
            this.buyerSupplierList.length = 0;

            if (utils.isEmptyId(this.supplierID)) {
                return;
            }

            const parameters = new BuyerSupplierSearchParameters();
            parameters.supplierID = this.supplierID;
            parameters.isDeleted = TriState.False;
            const responseData = await apiClient.post("api/buyerSupplier/search", parameters);
            this.buyerSupplierList.push(...responseData.list.map((b: IBuyerSupplier) => new BuyerSupplier(b)));
        }

        async saveSupplier(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();

            if (this.$v.supplier.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }

            const response: { newID: number } =  await apiClient.post("/api/supplier/save", this.supplier, event);
     
            if (this.supplier.isNew) {
                this.supplier.id = response.newID;
                this.supplierID = this.supplier.id;
                history.replaceState({}, "", this.$route.path.replace("/0", "/" + response.newID));
            }
            else if(this.supplier.isDeleted) {
                // saving un-deletes
                this.supplier.deleted = new Date(utils.emptyDateValue);
                this.supplier.deletedByUserID = 0;
            }
            dirtyness.setClean(this.dirtynessOptions);
            this.load(this.supplier.id);
            toastr.success("Saved");           
        }

        async deleteSupplier(event: Event) {
            if (this.supplier.isNew) return;

            const warningAcknowledged: boolean = await this.$bvModal.msgBoxConfirm(
                "This will delete a supplier record and all associated data including relationships with buyers, any module information complete or in progress, communications, contacts, users and all history. It is final and irreversible. You may want to consider making the supplier dormant first if you are not sure.", {
                title: "Warning!",
                okVariant: "danger",
                okTitle: "I understand, continue",
                cancelTitle: "Cancel",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0"
            });
            if (!warningAcknowledged) return;

            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm(
                `Are you sure you want to delete ${this.supplier.name}? You will NOT be able to undo!`, {
                title: "Delete Supplier",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0"
            });
            if (!shouldDelete) return;

            await apiClient.post("/api/supplier/delete", this.supplier, event);
            toastr.warning("Deleted");
            dirtyness.reset();
            this.goToSuppliers();
        }

        async editNewContact() {
            const contactsComponent: Contacts = this.$refs.contactsComponent as Contacts;
            contactsComponent.editNewContact();
        }

        @Validations()
        validations() {
            const validations = {
                supplier: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.supplier.name = { required };
            validations.supplier.address1 = { required };
            validations.supplier.postcode = { required };
            validations.supplier.countryID = { isNonZero: (value: number) => +value > 0 };
            validations.supplier.companyTypeID = { isNonZero: (value: number) => +value > 0 };
            validations.supplier.email = { email };
            validations.supplier.remittanceEmail = { email };
            
            return validations;
        }
    }
